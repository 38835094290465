import AuthService from '@/services/auth.service';
import PermissionService from '@/services/permission.service';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

const appRoutes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/layouts/NotFoundLayout.vue'),
  },
  {
    path: '/public',
    name: 'public-layout',
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: '',
        name: 'default-public',
        redirect: 'public/welcome',
      },
      {
        path: 'welcome',
        name: 'welcome',
        component: () => import('@/pages/public/WelcomePage.vue'),
      },
      {
        path: 'style-guide',
        name: 'style-guide',
        component: () => import('@/pages/public/StyleGuide.vue'),
      },
      {
        path: 'mobile-signup/:mode?/:key?',
        name: 'mobile-signup',
        component: () => import('@/pages/public/MobileSignupPage.vue'),
      }
    ],
  },
  {
    path: '/auth',
    name: 'auth-layout',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        name: 'default-auth',
        redirect: 'auth/login',
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/pages/auth/LoginPage.vue'),
      },
      {
        path: 'login-admin',
        name: 'login-admin',
        component: () => import('@/pages/admin/AdminLoginPage.vue'),
      }
    ],
  },
  {
    path: '/admin',
    name: 'admin-layout',
    component: () => import('@/layouts/AdminLayout.vue'),
    meta: {
      // requiresAdmin: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: () => import('@/pages/admin/AdminPage.vue'),
      },
      {
        path: 'companies',
        name: 'admin-companies',
        component: () => import('@/pages/admin/tenant/TenantPage.vue'),
      },
    ],
  },
  {
    path: '',
    name: 'app-layout',
    component: () => import('@/layouts/AppLayout.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'default',
        redirect: '/dashboard',
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/pages/dashboard/Dashboard.vue'),
        meta: {
          title: 'dashboard',
        },
      },
      {
        path: '/drivers',
        name: 'drivers',
        component: () => import('@/pages/drivers/DriversPage.vue'),
        meta: {
          title: 'Drivers',
        },
      },
      {
        path: '/drivers/calendar-overview',
        name: 'calendar-overview',
        component: () => import('@/pages/drivers/components/CalendarOverview.vue'),
        meta: {
          title: 'Calendar Overview',
        },
      },
      {
        path: '/driver/:id',
        name: 'driver',
        component: () => import('@/pages/drivers/DriverDetailPage.vue'),
        meta: {
          title: 'Driver Detail',
        },
      },
      {
        path: '/fleet',
        name: 'fleet',
        component: () => import('@/pages/fleet/FleetPage.vue'),
        meta: {
          title: 'Fleet',
        },
      },
      {
        path: '/trailerstands',
        name: 'trailer-stands',
        component: () => import('@/pages/trailer-stands/TrailerStandsPage.vue'),
        meta: {
          title: 'Trailer Stands',
        },
      },
      {
        path: '/fleet/trailer-detail/:id',
        name: 'trailer-detail',
        component: () => import('@/pages/fleet/components/TrailerDetail.vue'),
        meta: {
          title: 'Trailer Detail',
        },
      },
      {
        path: '/fleet/truck-detail/:id',
        name: 'truck-detail',
        component: () => import('@/pages/fleet/components/TruckDetail.vue'),
        meta: {
          title: 'Truck Detail',
        },
      },
      {
        path: '/check-reports',
        name: 'check-reports',
        component: () => import('@/pages/check-report/CheckReportPage.vue'),
        meta: {
          title: 'Check Reports',
        },
      },
      {
        path: '/check-entities',
        name: 'check-entities',
        component: () => import('@/pages/check-entity/CheckEntityView.vue'),
        meta: {
          title: 'Check Entities',
        },
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/pages/settings/SettingsPage.vue'),
        meta: {
          title: 'Settings',
          roles: ['admin', 'manger', 'planner'],
        },
      },
      {
        path: '/users',
        component: () => import('@/pages/users/UsersView.vue'),
      },
      {
        path: '/users/:id',
        component: () => import('@/pages/users/UsersView.vue'),
      },
      {
        path: '/chat-groups',
        component: () => import('@/pages/chat-groups/ChatGroupsView.vue'),
      },
      {
        path: '/timesheet',
        name: 'timesheet',
        component: () => import('@/pages/timesheet/TimesheetPage.vue'),
        meta: {
          title: 'Timesheet',
        },
      },
      {
        path: '/trips',
        name: 'trips',
        component: () => import('@/pages/trips/TripsView.vue'),
        meta: {
          title: 'Trips',
        },
      },
      {
        path: '/planning',
        name: 'planning',
        component: () => import('@/pages/planning/PlanningPage.vue'),
        meta: {
          title: 'Planning',
        },
      },
      {
        path: '/sites',
        name: 'sites',
        component: () => import('@/pages/sites/SitesPage.vue'),
        meta: {
          title: 'Sites',
        },
      },
      {
        path: '/fuel-cards',
        name: 'fuel-cards',
        component: () => import('@/pages/fuel-cards/FuelCardsView.vue'),
        meta: {
          title: 'Fuel Cards',
        },
      },
      {
        path: '/fuel',
        name: 'fuel',
        component: () => import('@/pages/fuel/FuelView.vue'),
        meta: {
          title: 'Fuel',
        },
      },
      {
        path: '/defect',
        name: 'defect',
        component: () => import('@/pages/defects/DefectView.vue'),
        meta: {
          title: 'Defect',
        },
      },
      {
        path: '/note',
        name: 'note',
        component: () => import('@/pages/notes/NotePage.vue'),
        meta: {
          title: 'Note',
        },
      },
      {
        path: '/user-profile',
        name: 'user-profile',
        component: () => import('@/pages/user-profile/UsersProfileView.vue'),
        meta: {
          title: 'User Profile',
        },
      }
    ],
  },
];

const routes = appRoutes;

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const { access, reason } = await PermissionService.canAccess(to.path);
    if (!access) {
      if (reason === 'No user') {
        next({ path: '/auth/login' });
      } else {
        next({ path: '/dashboard' });
      }
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    const authSrv = new AuthService();
    const user = await authSrv.getAdminUser();
    if (user) {
      next();
    } else {
      next({ path: '/auth/login-admin' });
    }
  } else {
    next();
  }
});

export default router;
