import * as CryptoJS from "crypto-js";

class CryptService {
	private key: string;
	private _sep: string;

	private _cfgKey: string;

	constructor() {
		this.key = "3f68e3c3-c2f3-4eb2-9e5a-1e482af8f4b7";
		this._sep = "";
		this._cfgKey = "";
	}

	public setSym(sym: string): void {
		this._sep = sym;
	}

	public setCfgKey(key: string): void {
		this._cfgKey = key;
	}

	getConfig() {
		if (!this._cfgKey) {
			return
		}
		return this.decrypt(this._cfgKey, true);
	}

	public crypt(data: string | object): string {
		if (typeof data === 'object') {
			data = JSON.stringify(data)
		}
		return CryptoJS.AES.encrypt(data, this.key)
			.toString()
			.split('/')
			.join(this._sep)
	}

	public decrypt(id: string, json = false): string | object {
		const decrypted = CryptoJS.AES.decrypt(
			id.split(this._sep).join("/"),
			this.key,
		).toString(CryptoJS.enc.Utf8);
		if (json) {
			return JSON.parse(decrypted);
		}
		return decrypted;
	}

	public sha1(v: string): string {
		return CryptoJS.SHA1(v).toString();
	}
}

export default new CryptService();
