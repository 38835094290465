import { Notify, Quasar } from 'quasar';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css';

// Import Quasar css
import 'quasar/src/css/index.sass';
import cryptService from './services/crypt.service';
import { createPinia } from 'pinia';
import { createI18nInstance } from './i18n'; // import the createI18nInstance function
import VueGoogleMaps from '@fawmi/vue-google-maps';

declare global {
  interface Window {
    appCfg: any;
  }
}

const initializeApp = async () => {
  const response = await fetch('/api/v1/public/config');
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const configData = await response.json();
  const { sym, key } = configData;

  // set config from the server
  cryptService.setSym(sym);
  cryptService.setCfgKey(key);

  const i18n = await createI18nInstance(); // Initialize i18n asynchronously

  const app = createApp(App)
    .use(createPinia())
    .use(i18n)
    .use(Quasar, {
      plugins: {
        Notify,
      },
    })
    .use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyAwDViM45tQAlyMP1Lw5gSeo7-4YyYIVm8',
        libraries: 'places', // if you need to use the places library
      },
    })
    .use(router);

  router.isReady().then(() => {
    app.mount('#app');
  });
};

initializeApp().catch((error) => {
  console.error('Failed to initialize the app:', error);
});
