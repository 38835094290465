// src/i18n.ts
import { createI18n } from 'vue-i18n';

const loadLocaleMessages = async () => {
  const locales = import.meta.glob('./locales/*.json');
  const messages: { [key: string]: any } = {};
  for (const path in locales) {
    const matched = path.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      const module = await locales[path]();
      messages[locale] = module.default;
    }
  }
  return messages;
};

export const createI18nInstance = async () => {
  const messages = await loadLocaleMessages();

  return createI18n({
    locale: 'en', // set default locale
    fallbackLocale: 'en', // set fallback locale
    messages,
  });
};
