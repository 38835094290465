import { useAppLayoutStore } from '@/stores/app-layout.store';
import FirebaseService from './firebase.service';

interface User {
  role_name: string;
  roles: string[];
  driver_type: string;
}

interface DecryptedData {
  user: User;
}

export default class PermissionService {

  static async getUser() {
    const loggedUser = await FirebaseService.getUserData();
    return loggedUser;
  }

  static async getRoles() {
    const loggedUser = await FirebaseService.getUserData();
    return loggedUser.user_attr.roles || [];
  }

  static async hasRole(role: string) {
    const loggedUser = await FirebaseService.getUserData();
    const roles = loggedUser.user_attr.roles;
    return roles?.indexOf(role) > -1;
  }

  static async canAccess(path: string) {
    const loggedUser = await FirebaseService.getUserData();
    if (!loggedUser) {
      return { access: false, reason: 'No user' };
    }
    const availablePublicRoutes = ['user-profile']
    const appLayoutStore = useAppLayoutStore();
    await appLayoutStore.getMenuItems();
    const found = appLayoutStore.menuItems.find((m) => {
      const splitRoute = path.split('/');
      if (availablePublicRoutes.includes(splitRoute[1])) {
        return true;
      }
      return m.path.indexOf(splitRoute[1]) > -1;
    });
    return { access: !!found, reason: found ? '' : 'No permission' };
  }
}

interface Role {
  key: string;
  name: string;
}

export const All_Roles: Role[] = [
  {
    key: 'admin',
    name: 'Administrator (include all)',
  },
  {
    key: 'tm',
    name: 'Transport Manager',
  },
  {
    key: 'planner',
    name: 'Planner',
  },
  {
    key: 'pm',
    name: 'Planner Manager',
  },
  {
    key: 'ops',
    name: 'Operator',
  },
  {
    key: 'hr',
    name: 'H.R.',
  },
  {
    key: 'gateway',
    name: 'Gateway',
  },
  {
    key: 'mechanic',
    name: 'Mechanic',
  },
];

interface PagePermission {
  [key: string]: string;
}

interface Page {
  text: string;
  icon: string;
  path: string;
  permission: 'all' | PagePermission;
}
