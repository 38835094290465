import { initializeApp } from 'firebase/app';
import { User, getAuth, onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import ClientStorage from './client-storage.service';
import cryptService from "./crypt.service";

export default class AuthService {
  static firebaseApp: any;
  static firebaseAuth: any;
  static db: any;
  static storage: any;

  constructor() {
    const config: any = cryptService.getConfig();
    // Initialize Firebase App
    AuthService.firebaseApp = initializeApp(config.firebase);
    // Initialize Firebase Auth
    AuthService.firebaseAuth = getAuth(AuthService.firebaseApp);
    // Initialize Firestore
    AuthService.db = getFirestore(AuthService.firebaseApp);
    // Initialize Storage
    AuthService.storage = getStorage(AuthService.firebaseApp);
  }

  // Login with custom token
  async loginWithCustomToken(customToken: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await signInWithCustomToken(AuthService.firebaseAuth, customToken);
        resolve(response.user);
      } catch (error) {
        reject(error);
      }
    })
  }

  getIdToken(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await this.getCurrentUser();
        if (!user) {
          return resolve('');
        }
        const token: any = await user.getIdToken(true);
        resolve(token);
      } catch (error) {
        reject(error);
      }
    });
  }

  getAdminUser() {
    const admin_token = ClientStorage.getItem('admin_token');
    if (!admin_token) {
      return null;
    }
    console.log('admin_token', admin_token);
    const admin_user = cryptService.decrypt(admin_token);
    console.log('admin_user', admin_user);
    return admin_user;
  }

  // Get current user
  getCurrentUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        AuthService.firebaseAuth,
        (user) => {
          unsubscribe();
          resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // Logout
  async logout(): Promise<void> {
    await signOut(AuthService.firebaseAuth);
  }
}

// export default new AuthService();
