import { IMenu } from "@/components/navigation/menu.interface";
import PermissionService from "@/services/permission.service";
import { defineStore } from "pinia";


export const useAppLayoutStore = defineStore({
    id: 'appLayout',
    state: () => ({
        menuItems: [] as IMenu[],
    }),
    actions: {
        async getMenuItems() {
            const response = await fetch(`/common/menu.json`);
            const data = await response.json();
            const user_roles = await PermissionService.getRoles();
            const all_menus = data.menu;
            const unfiltered_menus = [...all_menus];
            let app_menu: IMenu[] = [];
            for (let am of unfiltered_menus) {
                if (am.permission === 'all') {
                    app_menu.push(am);
                }
                if (
                    am.permission['admin'] === 'full' &&
                    user_roles.indexOf('admin') >= 0
                ) {
                    app_menu.push(am);
                } else if (
                    am.permission['tm'] === 'full' &&
                    user_roles.indexOf('tm') >= 0
                ) {
                    app_menu.push(am);
                } else if (
                    am.permission['planner'] === 'full' &&
                    user_roles.indexOf('planner') >= 0
                ) {
                    app_menu.push(am);
                } else if (
                    am.permission['ops'] === 'full' &&
                    user_roles.indexOf('ops') >= 0
                ) {
                    app_menu.push(am);
                } else if (
                    am.permission['gateway'] === 'full' &&
                    user_roles.indexOf('gateway') >= 0
                ) {
                    app_menu.push(am);
                } else if (
                    am.permission['hr'] === 'full' &&
                    user_roles.indexOf('hr') >= 0
                ) {
                    app_menu.push(am);
                }
            }
            this.menuItems = app_menu;
        }
    }
});
