const storageType = 'local';

export default class ClientStorage {

  static getItem(key: string): string | null | undefined {
    if (storageType === "local") {
      return localStorage.getItem(key);
    }
    if (storageType === "session") {
      return sessionStorage.getItem(key);
    }
    return undefined;
  }

  static setItem(key: string, value: string): void {
    if (storageType === "local") {
      localStorage.setItem(key, value);
    } else if (storageType === "session") {
      sessionStorage.setItem(key, value);
    }
  }

  static removeItem(key: string): void {
    if (storageType === "local") {
      localStorage.removeItem(key);
    } else if (storageType === "session") {
      sessionStorage.removeItem(key);
    }
  }
}
